import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { useState } from "react";
import HomePage from "./pages/HomePage";
import Search from "./pages/search";
import VideoPage from "./pages/video/VideoPage";
import ChannelPage from "./pages/channel";
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import { BiMenu } from "react-icons/bi";
import ShortsPage from "./pages/ShortsPage"; // New import
import SubscriptionsPage from "./pages/YouPage/Subscriptions/Subscriptions"; // New import
import YouPage from "./pages/YouPage/YouPage"; // New import
import History from "./pages/YouPage/History"; // New import
import Playlists from "./pages/YouPage/Playlists"; // New import
import YourVideos from "./pages/YouPage/YourVideos"; // New import
import WatchLater from "./pages/YouPage/WatchLater"; // New import
import LikedVideos from "./pages/YouPage/LikedVideos/LikedVideos"; // New import
import TrendingPage from "./pages/TrendingPage";
import NewestPage from "./pages/NewestPage";
import LegalLayout from "./components/layout/LegalLayout";

function Layout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const isVideoPage = location.pathname.startsWith("/video/");

  return (
    <div className="h-screen flex flex-col">
      <Navbar onMenuClick={() => setIsSidebarOpen(!isSidebarOpen)} />

      <div className="flex flex-1 h-[calc(100vh-56px)]">
        {isVideoPage ? (
          <>
            <button
              className="fixed top-3.5 left-4 z-40 p-2 hover:bg-gray-100 rounded-full"
              onClick={() => setIsSidebarOpen(true)}
            >
              <BiMenu className="w-6 h-6 text-gray-700" />
            </button>

            {isSidebarOpen && (
              <div
                className="fixed inset-0 z-50 bg-black bg-opacity-50"
                onClick={() => setIsSidebarOpen(false)}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <Sidebar isOpen={true} />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex-shrink-0">
            <Sidebar isOpen={isSidebarOpen} />
          </div>
        )}

        <main
          className={`flex-1 overflow-y-auto pt-14 ${
            !isVideoPage ? (isSidebarOpen ? "ml-64" : "ml-20") : "ml-0"
          }`}
        >
          <Routes>
            {/* Legal pages */}
            <Route path="/legal/*" element={<LegalLayout />} />

            <Route
              path="/"
              element={<HomePage isSidebarOpen={isSidebarOpen} />}
            />
            <Route
              path="/search"
              element={<Search isSidebarOpen={isSidebarOpen} />}
            />
            <Route path="/video/:id" element={<VideoPage />} />
            <Route path="/channel/:handle" element={<ChannelPage />} />
            <Route
              path="/shorts"
              element={<ShortsPage isSidebarOpen={isSidebarOpen} />}
            />
            <Route
              path="/subscriptions"
              element={<SubscriptionsPage isSidebarOpen={isSidebarOpen} />}
            />

            <Route
              path="/trending"
              element={<TrendingPage isSidebarOpen={isSidebarOpen} />}
            />

            <Route
              path="/newest"
              element={<NewestPage isSidebarOpen={isSidebarOpen} />}
            />

            <Route
              path="/you"
              element={<YouPage isSidebarOpen={isSidebarOpen} />}
            />

            {/* You section routes */}
            <Route path="/history" element={<History />} />
            <Route path="/playlists" element={<Playlists />} />
            <Route path="/your-videos" element={<YourVideos />} />
            <Route path="/watch-later" element={<WatchLater />} />
            <Route path="/liked-videos" element={<LikedVideos />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
