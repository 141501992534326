import { Link } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { MdVideoCall, MdNotifications } from "react-icons/md";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SignInButton, UserButton, useUser, useAuth } from "@clerk/clerk-react";
import { useChannel } from "../../hooks/useChannel";
import CreateChannelModal from "../studio/CreateChannelModal";
import SearchBar from "../search/SearchBar";
import YivLogo from "../../assests/images/yivster-logo.png";
import YivsterLogo from "../../assests/images/YivsterLogo.png";
import api from "../../config/axios";

export default function Navbar({ onMenuClick }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showCreateChannel, setShowCreateChannel] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { hasChannel, isLoading } = useChannel();
  const { user } = useUser();
  const { getToken } = useAuth();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const updateUserImage = async () => {
      if (user?.imageUrl) {
        try {
          const token = await getToken();
          const response = await api.put(
            "/users/me",
            { imageUrl: user.imageUrl },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("Updated user image in DB:", response.data);
        } catch (error) {
          console.error("Error updating user image:", error);
        }
      }
    };

    updateUserImage();
  }, [user?.imageUrl]);

  // Existing handlers remain the same...
  const handleStudioClick = () => {
    if (hasChannel) {
      window.location.href =
        process.env.REACT_APP_STUDIO_URL || "http://localhost:3001";
    } else {
      setShowCreateChannel(true);
    }
    setIsDropdownOpen(false);
  };

  const handleDashboardClick = (e) => {
    e.preventDefault();
    setIsDropdownOpen(false);
    navigate("/dashboard");
  };

  const handleUploadClick = () => {
    if (!hasChannel) {
      setShowCreateChannel(true);
    } else {
      const dashboardUrl =
        process.env.REACT_APP_STUDIO_URL || "http://localhost:3001";
      window.location.href = dashboardUrl;
    }
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest(".user-menu")) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 h-14 bg-white z-50 flex items-center justify-between px-4">
        {/* Left Section */}
        <div className="flex items-center gap-2">
          <button
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={onMenuClick}
          >
            <BiMenu className="w-6 h-6 text-gray-700" />
          </button>

          <Link to="/" className="flex items-center">
            <img
              src={YivsterLogo}
              alt="Yivster Logo"
              className="h-60 object-contain"
            />
            <span className="ml-2 text-lg font-small">Beta</span>
          </Link>
        </div>

        {/* Rest of the navbar remains the same... */}
        <div className="hidden md:flex flex-1 max-w-2xl mx-4">
          <SearchBar />
        </div>

        <div className="flex items-center gap-2">
          {isAuthenticated ? (
            <>
              <button
                onClick={handleUploadClick}
                className="p-2 hover:bg-gray-100 rounded-full"
                title="Create"
              >
                <MdVideoCall className="w-6 h-6 text-gray-700" />
              </button>

              <div className="relative user-menu">
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center space-x-2"
                >
                  <img
                    src={user?.imageUrl}
                    alt="Profile"
                    className="w-8 h-8 rounded-full"
                  />
                </button>

                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                    <button
                      onClick={handleStudioClick}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Creator Studio
                    </button>
                    <hr className="my-1" />
                    <UserButton
                      appearance={{
                        elements: {
                          rootBox:
                            "px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left",
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <SignInButton mode="modal">
              <button className="flex items-center gap-2 border border-gray-300 text-blue-600 px-4 py-1.5 rounded-full hover:bg-blue-50">
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                </svg>
                Sign in
              </button>
            </SignInButton>
          )}
        </div>
      </nav>

      {showCreateChannel && (
        <CreateChannelModal
          onClose={() => setShowCreateChannel(false)}
          onSuccess={() => {
            setShowCreateChannel(false);
            window.location.href =
              process.env.REACT_APP_STUDIO_URL || "http://localhost:3001";
          }}
        />
      )}
    </>
  );
}
