// components/channel/AboutModal.jsx
import React, { useState } from "react";
import { X, Share2 } from "lucide-react";
import ShareModal from "./ShareModal";
import { useToast } from "../shared/Toast";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaGithub,
  FaGlobe,
  FaDiscord,
  FaYoutube,
  FaTwitch,
} from "react-icons/fa6";

const getPlatformIcon = (platform) => {
  const icons = {
    twitter: <FaTwitter className="w-4 h-4 text-[#1DA1F2]" />,
    facebook: <FaFacebook className="w-4 h-4 text-[#1877F2]" />,
    instagram: <FaInstagram className="w-4 h-4 text-[#E4405F]" />,
    github: <FaGithub className="w-4 h-4" />,
    discord: <FaDiscord className="w-4 h-4 text-[#5865F2]" />,
    youtube: <FaYoutube className="w-4 h-4 text-[#FF0000]" />,
    twitch: <FaTwitch className="w-4 h-4 text-[#9146FF]" />,
    default: <FaGlobe className="w-4 h-4 text-gray-600" />,
  };

  return icons[platform.toLowerCase()] || icons.default;
};

const AboutModal = ({ isOpen, onClose, channelInfo, channelVideos = [] }) => {
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const { showToast, ToastComponent } = useToast();

  if (!isOpen) return null;

  const handleCopyChannelId = async () => {
    try {
      await navigator.clipboard.writeText(channelInfo.id);
      showToast("Channel ID copied to clipboard!");
      setShowShareDropdown(false);
    } catch (err) {
      showToast("Failed to copy channel ID", "error");
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
          {/* Header */}
          <div className="flex items-center justify-between p-6 border-b">
            <h2 className="text-xl font-medium">About</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6 space-y-6">
            {/* Description */}
            <div className="whitespace-pre-wrap">
              {channelInfo?.description}
            </div>

            {/* Links */}
            {channelInfo?.socialLinks &&
              Object.keys(channelInfo.socialLinks).length > 0 && (
                <div>
                  <h3 className="text-base font-medium mb-2">Links</h3>
                  <div className="space-y-3">
                    {Object.entries(channelInfo.socialLinks || {}).map(
                      ([platform, url]) => (
                        <a
                          key={platform}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                        >
                          {getPlatformIcon(platform)}
                          <span>{url}</span>
                        </a>
                      )
                    )}
                  </div>
                </div>
              )}

            {/* Channel details */}
            <div>
              <h3 className="text-base font-medium mb-2">Channel details</h3>
              <div className="space-y-4 text-sm">
                <div className="flex items-center gap-3">
                  <span>@{channelInfo?.handle}</span>
                </div>

                <div className="flex items-center gap-3">
                  <span>{channelInfo?.totalViews?.toLocaleString()} views</span>
                </div>

                <div className="flex items-center gap-3">
                  <span>
                    {channelInfo?.subscribers?.toLocaleString()} subscribers
                  </span>
                </div>

                <div className="flex items-center gap-3">
                  <span>{channelVideos?.length || 0} videos</span>
                </div>

                <div className="flex items-center gap-3">
                  <span>Joined {channelInfo?.joinedDate}</span>
                </div>

                {channelInfo?.location && (
                  <div className="flex items-center gap-3">
                    <span>{channelInfo.location}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="p-4 flex gap-2 border-t">
            <div className="relative">
              <button
                onClick={() => setShowShareDropdown(!showShareDropdown)}
                className="px-4 py-2 rounded-full flex items-center gap-2 hover:bg-gray-100"
              >
                <Share2 className="w-5 h-5" />
                Share channel
              </button>

              {showShareDropdown && (
                <div className="absolute bottom-full left-0 mb-2 bg-white rounded-lg shadow-lg border py-2 min-w-[200px]">
                  <button
                    onClick={() => {
                      setShowShareModal(true);
                      setShowShareDropdown(false);
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                  >
                    <Share2 className="w-4 h-4" />
                    Share channel
                  </button>
                  <button
                    onClick={handleCopyChannelId}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100"
                  >
                    Copy channel ID
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ShareModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        channelInfo={channelInfo}
      />
      {ToastComponent}
    </>
  );
};

export default AboutModal;
