// components/shared/Toast.jsx
import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { CheckCircle, XCircle, X } from "lucide-react";

export const toastConfig = {
  success: {
    icon: CheckCircle,
    className: "border-green-500 bg-green-50",
    iconClassName: "text-green-500",
  },
  error: {
    icon: XCircle,
    className: "border-red-500 bg-red-50",
    iconClassName: "text-red-500",
  },
};

const Toast = ({ message, type = "success", onClose }) => {
  const config = toastConfig[type];

  return (
    <div
      className={`fixed top-4 right-4 p-4 rounded-lg border shadow-lg flex items-center gap-2 animate-slide-in ${config.className}`}
    >
      <config.icon className={`w-5 h-5 ${config.iconClassName}`} />
      <span className="text-gray-800">{message}</span>
      <button
        onClick={onClose}
        className="ml-2 p-1 hover:bg-gray-200 rounded-full"
      >
        <X className="w-4 h-4" />
      </button>
    </div>
  );
};

// Toast manager hook
export const useToast = () => {
  const [toast, setToast] = useState(null);

  const showToast = (message, type = "success") => {
    setToast({ message, type });
  };

  const hideToast = () => {
    setToast(null);
  };

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(hideToast, 3000);
      return () => clearTimeout(timer);
    }
  }, [toast]);

  return {
    showToast,
    hideToast,
    ToastComponent: toast
      ? createPortal(
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={hideToast}
          />,
          document.body
        )
      : null,
  };
};
