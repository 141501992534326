function Playlists() {
  return (
    <div className="px-8 py-6">
      <h1 className="text-2xl font-bold mb-6">Playlists</h1>
      <div className="text-center py-20">
        <p className="text-gray-600">Feature coming soon!</p>
      </div>
    </div>
  );
}

export default Playlists;
