// pages/NewestPage.jsx
import React, { useEffect, useState } from "react";
import VideoCard from "../components/shared/VideoCard";
import api from "../config/axios";

function NewestPage() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("all");
  const [sortBy, setSortBy] = useState("newest");

  // Define tabs
  const tabs = [
    { id: "all", label: "All" },
    //{ id: "subscriptions", label: "From your subscriptions" },
    //{ id: "recommended", label: "Recommended" },
  ];

  useEffect(() => {
    const fetchNewestVideos = async () => {
      try {
        setLoading(true);
        const response = await api.get("/videos/newest", {
          params: {
            tab: activeTab,
            sort: sortBy,
          },
        });
        setVideos(response.data);
      } catch (err) {
        setError("Failed to fetch newest videos");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchNewestVideos();
  }, [activeTab, sortBy]);

  if (loading) {
    return (
      <div className="px-8 py-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Latest Videos</h1>
          <div className="w-32 h-10 bg-gray-200 rounded-lg animate-pulse" />
        </div>

        <div className="flex gap-4 mb-6 overflow-x-auto pb-2">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className="h-10 w-32 bg-gray-200 rounded-full animate-pulse"
            />
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {[...Array(24)].map((_, i) => (
            <div key={i} className="animate-pulse">
              <div className="bg-gray-200 h-48 rounded-lg mb-2" />
              <div className="bg-gray-200 h-4 w-3/4 mb-2" />
              <div className="bg-gray-200 h-4 w-1/2" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Latest Videos</h1>
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="px-8 py-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Latest Videos</h1>
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="px-4 py-2 border rounded-lg bg-white hover:bg-gray-50 cursor-pointer"
        >
          <option value="newest">Latest</option>
          <option value="popular">Most Popular</option>
        </select>
      </div>

      {/* Tabs */}
      <div className="flex gap-4 mb-6 overflow-x-auto pb-2">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-6 py-2 rounded-full whitespace-nowrap transition-colors ${
              activeTab === tab.id
                ? "bg-gray-100 font-medium"
                : "hover:bg-gray-50"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {videos.length === 0 ? (
        <div className="text-center py-20">
          <p className="text-gray-600">No videos available</p>
          <p className="text-sm text-gray-500 mt-2">
            New videos will appear here
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {videos.map((video) => (
            <VideoCard key={video._id} video={video} />
          ))}
        </div>
      )}
    </div>
  );
}

export default NewestPage;
