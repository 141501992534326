// TrendingPage.jsx
import React, { useEffect, useState } from "react";
import VideoCard from "../components/shared/VideoCard";
import api from "../config/axios";

function TrendingPage() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrendingVideos = async () => {
      try {
        setLoading(true);
        const response = await api.get("/videos/trending");
        setVideos(response.data);
      } catch (err) {
        setError("Failed to fetch trending videos");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingVideos();
  }, []);

  if (loading) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Trending</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {[...Array(24)].map((_, i) => (
            <div key={i} className="animate-pulse">
              <div className="bg-gray-200 h-48 rounded-lg mb-2" />
              <div className="bg-gray-200 h-4 w-3/4 mb-2" />
              <div className="bg-gray-200 h-4 w-1/2" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Trending</h1>
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="px-8 py-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Trending</h1>
      </div>

      {/* Filters - For now just showing "Now" */}
      <div className="flex gap-4 mb-6">
        <button className="px-4 py-2 bg-gray-100 rounded-full font-medium">
          Now
        </button>
        {/* Future filters will go here */}
        {/*        <button className="px-4 py-2 bg-gray-100 rounded-full font-medium">
          Now
        </button>
        <button className="px-4 py-2 bg-gray-100 rounded-full font-medium">
          Now
        </button>
        <button className="px-4 py-2 bg-gray-100 rounded-full font-medium">
          Now
        </button> */}
      </div>

      {/* Videos Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {videos.map((video) => (
          <VideoCard key={video._id} video={video} />
        ))}
      </div>
    </div>
  );
}

export default TrendingPage;
