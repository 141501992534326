import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDocumentTitle from "../hooks/useDocumentTitle";
import VideoCard from "../components/shared/VideoCard";
import VideoCardSkeleton from "../components/shared/VideoCardSkeleton";
import { setVideos, setLoading, setError } from "../redux/slices/videoSlice";
import api from "../config/axios";

function HomePage({ isSidebarOpen }) {
  const dispatch = useDispatch();
  const { videos, loading, error } = useSelector((state) => state.videos);

  useDocumentTitle("Home - Yivster");

  useEffect(() => {
    const fetchVideos = async () => {
      dispatch(setLoading(true));
      try {
        const response = await api.get("/videos");
        dispatch(setVideos(response.data));
      } catch (err) {
        console.error("Error fetching videos:", err);
        dispatch(
          setError(err.response?.data?.message || "Failed to fetch videos")
        );
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchVideos();
  }, [dispatch]);

  if (error) {
    return (
      <div className="p-6 text-red-500 flex items-center justify-center">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="px-8 py-6">
      <div
        className={`grid gap-x-4 gap-y-10 ${
          isSidebarOpen
            ? "grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"
            : "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
        }`}
      >
        {loading
          ? Array(12)
              .fill(null)
              .map((_, index) => (
                <div key={index} className="w-full">
                  <VideoCardSkeleton isSidebarOpen={isSidebarOpen} />
                </div>
              ))
          : videos.map((video) => (
              <VideoCard
                key={video._id}
                video={video}
                isSidebarOpen={isSidebarOpen}
              />
            ))}
      </div>
    </div>
  );
}

export default HomePage;
