import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../config/axios";
import SuggestedVideoCard from "./SuggestedVideoCard";
import { setSuggestedVideos, setLoading } from "../../redux/slices/videoSlice";

function SuggestedVideos({ currentVideoId }) {
  const dispatch = useDispatch();
  const { currentVideo, suggestedVideos, loading } = useSelector(
    (state) => state.videos
  );
  const fetchedRef = useRef(false);

  useEffect(() => {
    const fetchSuggestedVideos = async () => {
      if (!currentVideo?.tags?.length) {
        console.log("No tags found in current video");
        return;
      }

      try {
        console.log("Fetching with tags:", currentVideo.tags);
        const response = await api.get("/videos/recommended", {
          params: {
            tags: currentVideo.tags.join(","),
            excludeVideoId: currentVideoId,
          },
        });
        console.log("API response:", response.data);
        dispatch(setSuggestedVideos(response.data));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchSuggestedVideos();
  }, [currentVideoId, currentVideo?.tags]);

  if (!suggestedVideos?.length) {
    return <div>No suggested videos found</div>;
  }

  if (loading) {
    return (
      <div className="w-full">
        <h2 className="text-lg font-bold mb-4">Suggested Videos</h2>
        <div className="space-y-3">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="animate-pulse">
              <div className="flex gap-2 mb-3">
                <div className="bg-gray-200 w-40 aspect-video rounded-lg"></div>
                <div className="flex-1">
                  <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      {suggestedVideos.map((video) => (
        <SuggestedVideoCard key={video._id} video={video} />
      ))}
    </div>
  );
}

export default SuggestedVideos;
