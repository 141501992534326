import { Link } from "react-router-dom";
import { formatViewCount, timeAgo } from "../../utils/formatters";

function SearchCard({ video }) {
  return (
    <div className="flex gap-4 max-w-[850px] hover:bg-gray-100/50 rounded-xl p-2 -mx-2">
      {/* Thumbnail */}
      <Link to={`/video/${video._id}`} className="relative flex-shrink-0">
        <img
          src={video.thumbnailUrl}
          alt={video.title}
          className="w-[360px] aspect-video object-cover rounded-xl"
        />
        {video.duration && (
          <span className="absolute bottom-2 right-2 bg-black/80 text-white text-xs px-1 py-0.5 rounded font-medium">
            {video.duration}
          </span>
        )}
      </Link>

      {/* Info */}
      <div className="flex-1">
        <Link to={`/video/${video._id}`}>
          <h3 className="text-lg font-medium line-clamp-2 mb-1">
            {video.title}
          </h3>
        </Link>

        <div className="flex items-center text-xs text-gray-600 mt-1">
          <span>{formatViewCount(video.views)} views</span>
          <span className="mx-1">•</span>
          <span>{timeAgo(video.createdAt)}</span>
        </div>

        <Link
          to={`/channel/@${video.channelId.handle}`}
          className="flex items-center mt-3 mb-2"
        >
          <img
            src={video.channelId.ownerId?.imageUrl} // Update this line
            alt={video.channelId.name}
            className="w-6 h-6 rounded-full mr-2"
          />
          <span className="text-sm text-gray-700 font-medium">
            {video.channelId.name}
            {video.channelId.isVerified && (
              <span className="ml-1 text-gray-400">✓</span>
            )}
          </span>
        </Link>

        {video.description && (
          <p className="text-sm text-gray-600 line-clamp-2 mt-2">
            {video.description}
          </p>
        )}
      </div>
    </div>
  );
}

export default SearchCard;
