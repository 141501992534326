import React from "react";
import { Link } from "react-router-dom";
import {
  MdHomeFilled,
  MdOutlineVideoLibrary,
  MdHistory,
  MdOutlineWatchLater,
  MdOutlineThumbUp,
  MdSettings,
  MdOutlineHelpOutline,
  MdOutlineFeedback,
  MdOutlineTrendingUp,
  MdOutlineNewReleases,
} from "react-icons/md";
// import { SiYoutubeshorts } from "react-icons/si";
import { BsCollectionPlay } from "react-icons/bs";
import { IoIosPlay } from "react-icons/io";

function Sidebar({ isOpen }) {
  // Links for closed sidebar (including You)
  const closedLinks = [
    { icon: <MdHomeFilled />, label: "Home", path: "/" },
    // { icon: <SiYoutubeshorts />, label: "Shorts", path: "/shorts" },
    {
      icon: <BsCollectionPlay />,
      label: "Subscriptions",
      path: "/subscriptions",
    },
    {
      icon: <MdOutlineVideoLibrary />,
      label: "You",
      path: "/you", // Make sure this matches your route
    },
  ];

  // Links for opened sidebar (without You since it has its own section)
  const mainLinks = [
    { icon: <MdHomeFilled />, label: "Home", path: "/" },
    // { icon: <SiYoutubeshorts />, label: "Shorts", path: "/shorts" },
    {
      icon: <BsCollectionPlay />,
      label: "Subscriptions",
      path: "/subscriptions",
    },
  ];

  const youLinks = [
    { icon: <MdHistory />, label: "History", path: "/history" },
    { icon: <BsCollectionPlay />, label: "Playlists", path: "/playlists" },
    { icon: <BsCollectionPlay />, label: "Your videos", path: "/your-videos" },
    {
      icon: <MdOutlineWatchLater />,
      label: "Watch later",
      path: "/watch-later",
    },
    {
      icon: <MdOutlineThumbUp />,
      label: "Liked videos",
      path: "/liked-videos",
    },
  ];

  const exploreLinks = [
    { icon: <MdOutlineTrendingUp />, label: "Trending", path: "/trending" },
    { icon: <MdOutlineNewReleases />, label: "Newest", path: "/newest" },
  ];

  /*   const yivsterServices = [
    {
      icon: <IoIosPlay className="text-red-600" />,
      label: "Yivster Premium",
      path: "/premium",
    },
    {
      icon: <IoIosPlay className="text-red-600" />,
      label: "Yivster Studio",
      path: "/studio",
    },
    {
      icon: <IoIosPlay className="text-red-600" />,
      label: "Yivster Music",
      path: "/music",
    },
    {
      icon: <IoIosPlay className="text-red-600" />,
      label: "Yivster Kids",
      path: "/kids",
    },
  ]; */

  const footerLinks = [
    /*     { label: "About", path: "/about" },
    { label: "Press", path: "/press" },
    { label: "Copyright", path: "/copyright" },
    { label: "Contact us", path: "/contact" },
    { label: "Creators", path: "/creators" }, */
    { label: "Terms", path: "legal/terms" },
    { label: "Privacy", path: "legal/privacy" },
    { label: "Guidelines & Safety", path: "legal/guidelines" },
  ];

  const renderLink = (item, index) => (
    <Link
      key={index}
      to={item.path || "#"}
      className={`flex items-center ${
        isOpen
          ? "px-3 py-2 hover:bg-gray-100 mx-2"
          : "px-1 py-4 hover:bg-gray-100 flex-col"
      } rounded-lg`}
    >
      <div className={`text-[24px] ${!isOpen && "mb-1"}`}>{item.icon}</div>
      <span className={`${isOpen ? "ml-6 text-sm" : "text-[10px]"}`}>
        {item.label}
      </span>
    </Link>
  );

  return (
    <aside
      className={`fixed left-0 top-14 h-[calc(100vh-56px)] bg-white transition-all duration-200 overflow-y-auto ${
        isOpen ? "w-64" : "w-20"
      }`}
    >
      <div className="flex flex-col">
        {/* Show different links based on sidebar state */}
        <div className="pb-2">
          {isOpen
            ? mainLinks.map((link, index) => renderLink(link, index))
            : closedLinks.map((link, index) => renderLink(link, index))}
        </div>

        {isOpen && (
          <>
            <hr className="my-2" />
            {/* You section */}
            <div className="px-4 py-2">
              <Link
                to="/you"
                className="flex items-center px-3 py-2 hover:bg-gray-100 mx-2 rounded-lg"
              >
                <h3 className="px-2 text-base font-medium mb-1">You</h3>
                <span className="ml-auto text-sm">›</span>
              </Link>
              {youLinks.map((link, index) => renderLink(link, index))}
            </div>{" "}
            <hr className="my-2" />
            {/* Explore section */}
            <div className="px-4 py-2">
              <h3 className="px-2 text-base font-medium mb-1">Explore</h3>
              {exploreLinks.map((link, index) => renderLink(link, index))}
            </div>
            <hr className="my-2" />
            {/* Subscriptions */}
            {/*            <div className="px-4 py-2">
              <h3 className="px-2 text-base font-medium mb-1">Subscriptions</h3>
              <div className="space-y-1">
                {[
                  "BuzzFeedVideo",
                  "MulliganBrothers",
                  "Desus & Mero",
                  "Music",
                  "Neil Patel",
                  "GaryVee",
                  "Jaden",
                ].map((channel) => (
                  <Link
                    key={channel}
                    to="#"
                    className="flex items-center px-2 py-2 hover:bg-gray-100 rounded-lg"
                  >
                    <div className="w-6 h-6 bg-gray-200 rounded-full mr-6" />
                    <span className="text-sm">{channel}</span>
                  </Link>
                ))}
                <button className="flex items-center px-2 py-2 hover:bg-gray-100 rounded-lg w-full">
                  <span className="text-sm">Show more</span>
                </button>
              </div>
            </div> 
            <hr className="my-2" /> */}
            {/* More from Yivster */}
            {/*     <div className="px-4 py-2">
              <h3 className="px-2 text-base font-medium mb-1">
                More from Yivster
              </h3>
              {yivsterServices.map((service, index) => (
                <Link
                  key={index}
                  to={service.path}
                  className="flex items-center px-2 py-2 hover:bg-gray-100 rounded-lg"
                >
                  <div className="text-[24px] mr-6">{service.icon}</div>
                  <span className="text-sm">{service.label}</span>
                </Link>
              ))}
            </div> 
            <hr className="my-2" /> */}
            {/* Footer */}
            <div className="px-6 py-4 text-[13px] text-gray-600">
              <div className="space-x-2">
                {footerLinks.slice(0, 3).map((link, index) => (
                  <Link key={index} to={link.path} className="hover:underline">
                    {link.label}
                  </Link>
                ))}
              </div>
              <div className="space-x-2 mt-2">
                {footerLinks.slice(3, 5).map((link, index) => (
                  <Link key={index} to={link.path} className="hover:underline">
                    {link.label}
                  </Link>
                ))}
              </div>
              <div className="space-x-2 mt-2">
                {footerLinks.slice(5).map((link, index) => (
                  <Link key={index} to={link.path} className="hover:underline">
                    {link.label}
                  </Link>
                ))}
              </div>
              <div className="mt-4">© 2024 Yivster LLC</div>
            </div>
          </>
        )}
      </div>
    </aside>
  );
}

export default Sidebar;
