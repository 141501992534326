import React from "react";
import { SignInButton, useAuth } from "@clerk/clerk-react";
import VideoCard from "../../../components/shared/VideoCard";
import api from "../../../config/axios";

function SubscriptionsPage() {
  const { isSignedIn } = useAuth();
  const [videos, setVideos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchSubscriptionVideos = async () => {
      if (!isSignedIn) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await api.get("/videos/subscribed");
        setVideos(response.data);
      } catch (err) {
        setError("Failed to fetch subscription videos");
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionVideos();
  }, [isSignedIn]);

  if (!isSignedIn) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[70vh] px-4">
        <div className="text-center max-w-md">
          <h2 className="text-2xl font-bold mb-4">
            Sign in to view subscriptions
          </h2>
          <p className="text-gray-600 mb-8">
            Subscribe to your favorite channels to see their latest videos here
            and never miss an upload!
          </p>
          <SignInButton mode="modal">
            <button className="px-6 py-3 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors">
              Sign In
            </button>
          </SignInButton>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="px-8 py-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {[...Array(12)].map((_, i) => (
            <div key={i} className="animate-pulse">
              <div className="bg-gray-200 h-48 rounded-lg mb-2" />
              <div className="bg-gray-200 h-4 w-3/4 mb-2" />
              <div className="bg-gray-200 h-4 w-1/2" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="px-8 py-6 text-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (!videos.length) {
    return (
      <div className="px-8 py-20 text-center">
        <h2 className="text-xl font-semibold mb-4">No videos to show</h2>
        <p className="text-gray-600 mb-4">
          All Of These Amazing Videos Here And You Still Haven't Subscribed
        </p>
      </div>
    );
  }

  return (
    <div className="px-8 py-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {videos.map((video) => (
          <VideoCard key={video._id} video={video} />
        ))}
      </div>
    </div>
  );
}

export default SubscriptionsPage;
