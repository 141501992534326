import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HorizontalVideoCard from "../../../components/shared/HorizontalVideoCard";
import { setLikedVideos, setLoading } from "../../../redux/slices/videoSlice";
import api from "../../../config/axios";

function LikedVideosPreview() {
  const dispatch = useDispatch();
  const { likedVideos, loading } = useSelector((state) => state.videos);

  useEffect(() => {
    const fetchLikedVideos = async () => {
      try {
        const response = await api.get("/users/me/liked");
        dispatch(setLikedVideos(response.data));
      } catch (err) {
        console.error(err);
      }
    };

    fetchLikedVideos();
  }, [dispatch]);

  if (loading) return null;

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between px-8 mb-4">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-medium">Liked videos</h2>
          {likedVideos?.length > 0 && (
            <span className="text-sm text-gray-600">
              {likedVideos.length} video{likedVideos.length !== 1 ? "s" : ""}
            </span>
          )}
        </div>
        {likedVideos?.length > 0 && (
          <Link
            to="/liked-videos"
            className="text-sm font-medium px-3 py-1 hover:bg-gray-100 rounded-full"
          >
            View all
          </Link>
        )}
      </div>

      <div className="px-8">
        {likedVideos.length === 0 ? (
          <div className="text-gray-500">No liked videos yet</div>
        ) : (
          <div className="space-y-2">
            {likedVideos.slice(0, 5).map((video) => (
              <HorizontalVideoCard key={video._id} video={video} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default LikedVideosPreview;
