// VideoCard.jsx
import React from "react";
import { Link } from "react-router-dom";
import {
  formatDuration,
  formatViewCount,
  timeAgo,
} from "../../utils/formatters";

function VideoCard({ video, isSidebarOpen }) {
  const defaultThumbnail = "https://placehold.co/320x180";
  const defaultAvatar = "https://placehold.co/40x40";

  const handleImageError = (e, type) => {
    console.error(`${type} load error:`, e);
    e.target.src = type === "thumbnail" ? defaultThumbnail : defaultAvatar;
  };

  console.log(video);

  return (
    <Link
      to={`/video/${video._id}`}
      className={`block group ${
        isSidebarOpen ? "max-w-[360px]" : "max-w-[400px]"
      }`}
    >
      <div className="w-full">
        <Link to={`/video/${video._id}`} className="block group w-full">
          <div className="flex flex-col">
            {/* Thumbnail Container */}
            <div className="relative w-full rounded-xl overflow-hidden">
              <img
                src={video.thumbnailUrl}
                alt={video.title}
                className="w-full aspect-video object-cover transition-transform duration-200 group-hover:scale-105"
                onError={(e) => handleImageError(e, "thumbnail")}
              />
              {video.duration && (
                <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 text-white text-xs px-1 py-0.5 rounded text-[12px] font-medium">
                  {formatDuration(video.duration)}
                </span>
              )}
            </div>

            {/* Video Info */}
            <div className="flex mt-3 px-1">
              {/* <Link to={`/channel/${channelInfo.handle}`}> */}
              <img
                src={video.channelId?.avatar}
                // src={video.channelId?.ownerId?.imageUrl}
                alt={video.channelId?.name}
                className="w-9 h-9 rounded-full object-cover flex-shrink-0"
                onError={(e) => handleImageError(e, "avatar")}
              />
              {/* </Link> */}
              <div className="ml-3 flex-1">
                <Link to={`/video/${video._id}`} className="block group w-full">
                  <h3 className="font-medium text-sm line-clamp-2 leading-5">
                    {video.title}
                  </h3>
                </Link>
                {/* <Link to={`/channel/${channelInfo.handle}`}> */}
                <p className="text-[13px] text-gray-600 mt-1 hover:text-gray-900">
                  {video.channelId?.displayHandle || video.channelId?.handle}
                </p>
                {/* </Link> */}
                <div className="text-[13px] text-gray-600">
                  <span>{formatViewCount(video.views)} views</span>
                  <span className="mx-1">•</span>
                  <span>{timeAgo(video.createdAt)}</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </Link>
  );
}

export default VideoCard;
