import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux"; // Add this
import {
  BiFullscreen,
  BiExitFullscreen,
  BiPlay,
  BiPause,
} from "react-icons/bi";
import { HiVolumeUp, HiVolumeOff } from "react-icons/hi";
import { updateVideoInteractions } from "../../redux/slices/videoSlice"; // Add this
import api from "../../config/axios"; // Adjust path as needed

function VideoPlayer({ videoUrl, videoId }) {
  const dispatch = useDispatch(); // Add this
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [showControls, setShowControls] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);
  const [viewRegistered, setViewRegistered] = useState(false);
  const watchTimeRef = useRef(0);
  const lastUpdateRef = useRef(Date.now());
  const [isRegistering, setIsRegistering] = useState(false);
  const hasMetViewingCriteria = useRef(false);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      videoContainerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleProgress = (e) => {
    const progressBar = e.currentTarget;
    const clickPosition =
      (e.pageX - progressBar.offsetLeft) / progressBar.offsetWidth;
    const newTime = clickPosition * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  const handleVideoLoad = () => {
    setIsLoading(false);
    setDuration(videoRef.current.duration);
  };

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      // Update the time display
      setCurrentTime(video.currentTime);

      // Handle view counting
      if (!video.paused && !viewRegistered && !isRegistering) {
        const now = Date.now();
        const timeDiff = (now - lastUpdateRef.current) / 1000;
        watchTimeRef.current += timeDiff;
        lastUpdateRef.current = now;

        console.log(
          "Watch time:",
          watchTimeRef.current,
          "Duration:",
          video.duration
        ); // Debug log

        if (!hasMetViewingCriteria.current) {
          if (video.duration <= 30) {
            if (video.currentTime >= video.duration * 0.95) {
              console.log("Registering view for short video"); // Debug log
              hasMetViewingCriteria.current = true;
              registerView();
            }
          } else {
            if (watchTimeRef.current >= 30) {
              console.log("Registering view for long video"); // Debug log
              hasMetViewingCriteria.current = true;
              registerView();
            }
          }
        }
      }
    };

    const handlePlay = () => {
      lastUpdateRef.current = Date.now();
    };

    const registerView = async () => {
      if (isRegistering || viewRegistered) return;

      try {
        setIsRegistering(true);
        console.log("Attempting to register view for video:", videoId);
        const response = await api.post(`/videos/${videoId}/view`);
        console.log("View registered:", response.data);
        setViewRegistered(true);
        dispatch(updateVideoInteractions({ views: response.data.views }));
      } catch (error) {
        console.error("Error registering view:", error);
        hasMetViewingCriteria.current = false; // Reset if failed
      } finally {
        setIsRegistering(false);
      }
    };

    video.addEventListener("timeupdate", handleTimeUpdate);
    video.addEventListener("play", handlePlay);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
      video.removeEventListener("play", handlePlay);
    };
  }, [videoId, viewRegistered, isRegistering, dispatch]);

  return (
    <div
      ref={videoContainerRef}
      className="relative bg-black w-full rounded-lg overflow-hidden"
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black">
          <div className="w-12 h-12 border-4 border-gray-600 border-t-gray-200 rounded-full animate-spin"></div>
        </div>
      )}

      <div className="relative aspect-video">
        <video
          ref={videoRef}
          className="w-full h-full cursor-pointer"
          src={videoUrl}
          onLoadedData={handleVideoLoad}
          onTimeUpdate={handleTimeUpdate}
          onClick={handlePlayPause} // Added click handler to video element
        />

        {/* Custom Controls */}
        <div
          className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4 transition-opacity duration-300 ${
            showControls ? "opacity-100" : "opacity-0"
          }`}
        >
          {/* Progress Bar */}
          <div
            className="w-full h-1 bg-gray-600 cursor-pointer mb-4"
            onClick={handleProgress}
          >
            <div
              className="h-full"
              style={{
                width: `${(currentTime / duration) * 100}%`,
                backgroundColor: "#9147ff",
              }}
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              {/* Play/Pause */}
              <button
                onClick={handlePlayPause}
                className="text-white hover:bg-white/20 rounded-full p-2"
              >
                {isPlaying ? <BiPause size={24} /> : <BiPlay size={24} />}
              </button>

              {/* Volume */}
              <div className="flex items-center gap-2">
                <button className="text-white">
                  {volume > 0 ? (
                    <HiVolumeUp size={24} />
                  ) : (
                    <HiVolumeOff size={24} />
                  )}
                </button>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="w-20"
                />
              </div>

              {/* Time */}
              <div className="text-white text-sm">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
            </div>

            <div className="flex items-center gap-2">
              {/* Fullscreen Toggle */}
              <button
                onClick={handleFullscreen}
                className="text-white hover:bg-white/20 rounded-full p-2"
              >
                {isFullscreen ? (
                  <BiExitFullscreen size={24} />
                ) : (
                  <BiFullscreen size={24} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
