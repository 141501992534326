import React from "react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { BiDotsVertical } from "react-icons/bi";

function HorizontalVideoCard({ video }) {
  return (
    <div className="flex gap-3 mb-2 group">
      <Link to={`/video/${video._id}`} className="relative flex-shrink-0">
        <img
          src={video.thumbnailUrl}
          alt={video.title}
          className="w-40 h-[90px] object-cover rounded"
        />
        <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 text-white text-xs px-1 rounded">
          {video.duration}
        </span>
      </Link>

      <div className="flex-1 min-w-0">
        <div className="flex justify-between items-start">
          <Link to={`/video/${video._id}`}>
            <h3 className="text-sm font-medium line-clamp-2 leading-5">
              {video.title}
            </h3>
          </Link>
          <button className="p-1.5 hover:bg-gray-100 rounded-full opacity-0 group-hover:opacity-100">
            <BiDotsVertical />
          </button>
        </div>

        <Link
          to={`/channel/${video.channelId?.handle}`}
          className="text-xs text-gray-600 hover:text-gray-900"
        >
          {video.channelId?.name}
        </Link>

        <div className="text-xs text-gray-600 flex items-center">
          <span>{video.views} views</span>
          <span className="mx-1">•</span>
          <span>
            {formatDistance(new Date(video.createdAt), new Date(), {
              addSuffix: true,
            })}
          </span>
        </div>
      </div>
    </div>
  );
}

export default HorizontalVideoCard;
