// components/video/CommentItem.jsx
import React, { useState } from "react";
import { formatDistance } from "date-fns";
import { BiLike, BiDislike } from "react-icons/bi";
import AddComment from "./AddComment";
import SignInPrompt from "../shared/SignInPrompt";
import { useAuth } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";

function CommentItem({ comment, onReply, onLike, onDislike, isReply = false }) {
  const { user } = useUser();
  const { isSignedIn } = useAuth();
  const [isReplying, setIsReplying] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [signInAction, setSignInAction] = useState(null);
  const [likes, setLikes] = useState(comment.likes || []);
  const [dislikes, setDislikes] = useState(comment.dislikes || []);
  const [hasLiked, setHasLiked] = useState(false);
  const [hasDisliked, setHasDisliked] = useState(false);

  //const [getDisplayImage, setDisplayImage] = useState(null);

  /*   const handleLike = () => {
    if (hasLiked) {
      setLikes(likes.filter((id) => id !== comment._id));
      setHasLiked(false);
    } else {
      setLikes([...likes, comment._id]);
      setHasLiked(true);
      if (hasDisliked) {
        setDislikes(dislikes.filter((id) => id !== comment._id));
        setHasDisliked(false);
      }
    }
    onLike(comment._id);
  }; */

  const handleLike = () => {
    if (!isSignedIn) {
      setSignInAction("like");
      setShowSignInPrompt(true);
      return;
    }

    if (hasLiked) {
      setLikes(likes.filter((id) => id !== comment._id));
      setHasLiked(false);
    } else {
      setLikes([...likes, comment._id]);
      setHasLiked(true);
      if (hasDisliked) {
        setDislikes(dislikes.filter((id) => id !== comment._id));
        setHasDisliked(false);
      }
    }
    onLike(comment._id);
  };

  const handleDislike = () => {
    if (!isSignedIn) {
      setSignInAction("dislike");
      setShowSignInPrompt(true);
      return;
    }

    if (hasDisliked) {
      setDislikes(dislikes.filter((id) => id !== comment._id));
      setHasDisliked(false);
    } else {
      setDislikes([...dislikes, comment._id]);
      setHasDisliked(true);
      if (hasLiked) {
        setLikes(likes.filter((id) => id !== comment._id));
        setHasLiked(false);
      }
    }
    onDislike(comment._id);
  };

  const handleReplyClick = () => {
    setIsReplying(!isReplying);
  };

  console.log(comment.displayAvatar);

  return (
    <div className={`flex gap-4 ${isReply ? "ml-12" : ""}`}>
      <img
        src={comment.userId?.imageUrl} // If you're populating the userId
        alt={comment.displayName}
        className="w-10 h-10 rounded-full"
        onError={(e) => {
          e.target.src =
            "https://img.clerk.com/eyJ0eXBlIjoiZGVmYXVsdCIsImlpZCI6Imluc18ycEpJRm1YSTJsTUlJcU9BZGVoTzFyNndmWUYiLCJyaWQiOiJ1c2VyXzJwamd0Y0oyR1lqZDVldUt3d0tNb01XMHRkZyJ9";
        }}
      />
      <div className="flex-1">
        <div className="flex items-center gap-2">
          <span className="font-medium text-[13px]">{comment.displayName}</span>
          <span className="text-[13px] text-gray-600">
            {formatDistance(new Date(comment.createdAt), new Date(), {
              addSuffix: true,
            })}
          </span>
        </div>
        <p className="mt-1 text-[14px]">
          {/* Add the @ mention if it's a reply */}
          {comment.replyToUser && (
            <span className="text-blue-600 mr-1">@{comment.replyToUser} </span>
          )}
          {comment.content}
        </p>

        {/* Restore original like/dislike buttons */}
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={handleLike}
            className={`flex items-center p-2 hover:bg-gray-100 rounded-full ${
              hasLiked ? "text-blue-600" : "text-gray-600"
            }`}
          >
            <BiLike size={16} />
            <span className="ml-1 text-xs">{likes.length}</span>
          </button>
          <button
            onClick={handleDislike}
            className={`flex items-center p-2 hover:bg-gray-100 rounded-full ${
              hasDisliked ? "text-blue-600" : "text-gray-600"
            }`}
          >
            <BiDislike size={16} />
            <span className="ml-1 text-xs">{dislikes.length}</span>
          </button>
          <button
            onClick={handleReplyClick}
            className="px-2 py-1.5 hover:bg-gray-100 rounded-full text-[13px] font-medium"
          >
            Reply
          </button>
        </div>

        {/* Show replies */}
        {comment.replies?.length > 0 && !isReply && (
          <div className="mt-2">
            <button
              onClick={() => setShowReplies(!showReplies)}
              className="text-[#065fd4] text-[14px] font-medium hover:text-[#065fd4]/90 flex items-center gap-2"
            >
              <span className={`w-4 h-4 ${showReplies ? "rotate-180" : ""}`}>
                ▼
              </span>
              {showReplies ? "Hide" : `Show ${comment.replies.length}`} replies
            </button>
            {showReplies && (
              <div className="mt-4">
                {comment.replies.map((reply) => (
                  <CommentItem
                    key={reply._id}
                    comment={reply}
                    onReply={onReply}
                    onLike={onLike}
                    onDislike={onDislike}
                    isReply={true}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        {/* Reply input */}
        {isReplying && (
          <div className="mt-4">
            <AddComment
              onSubmit={(content) => {
                onReply(comment._id, content);
                setIsReplying(false);
              }}
              userImage={user?.imageUrl} // Use current user's image for the reply form
            />
          </div>
        )}
      </div>

      <SignInPrompt
        isOpen={showSignInPrompt}
        onClose={() => setShowSignInPrompt(false)}
        action={signInAction}
      />
    </div>
  );
}

export default CommentItem;
